import React from 'react'

import { Box, useProps } from '@affstore-ds/uikit'

import { AdvertiserLogoPropertiesInterface } from './AdvertiserLogo.types'

import { getAdvertiserLogoPath } from '../../utils'

import { useAdvertiserLogoStyles } from './AdvertiserLogo.styled'

const defaultProperties: Pick<AdvertiserLogoPropertiesInterface, 'size'> = {
  size: 's',
}

export const AdvertiserLogo = (properties: AdvertiserLogoPropertiesInterface) => {
  const { size, iconName, className, alt, ...rest } = useProps('AdvertiserLogo', defaultProperties, properties)
  const { classes, cx } = useAdvertiserLogoStyles()

  if (!iconName) {
    return null
  }

  return (
    <Box
      data-testid="advertiser-logo"
      component="img"
      className={cx(classes.root, className)}
      alt={alt || 'Advertiser logo'}
      src={getAdvertiserLogoPath({ size, iconName })}
      {...rest}
    />
  )
}
