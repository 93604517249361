import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { CreditCardDown13 } from '@affstore-ds/icons'
import { AppShell, Divider, Group, NavLink, Skeleton, Stack, Text } from '@affstore-ds/uikit'

import { useBalance } from '../../entities/balance/model/useBalance'
import { useFirstLastName } from './hooks/useFirstLastName'

import { LanguageDropdown } from '../../features/LanguageDropdown'
import { Money } from '../../shared/components/Money'
import { profileTabSettings } from '../../shared/navigation/NavMenuData'
import { routes } from '../../shared/routes'

import { PaymentInfo } from './containers/PaymentInfo'
import { headerMainTranslationKeys } from './translations/headerMain.translationKeys'
import { useHeaderMainStyles } from './HeaderMain.styled'

export const HeaderMain: FC = () => {
  const { i18n } = useI18n()
  const { classes } = useHeaderMainStyles()

  const {
    auth: { user },
  } = useAuth()

  const { balanceData, balanceIsFetching } = useBalance()

  const { firstName, lastName } = useFirstLastName(user?.legal_profile)

  return (
    <AppShell.Header h={72} className={classes.header}>
      <LanguageDropdown type="cabinet" />
      <Group className={classes.headerControls} gap={20} align="center">
        {user?.is_onboarding_passed && <PaymentInfo />}
        <Divider m="auto" orientation="vertical" className={classes.divider} />
        {balanceIsFetching ? (
          <Stack gap={12}>
            <Skeleton width={89} height={12} />
            <Skeleton width={89} height={12} />
          </Stack>
        ) : (
          <Stack gap={0}>
            <Text className={classes.balance} elementSize="xl" elementVariant="important" colorVariant="greyIron.18">
              <Money value={balanceData.amount} currency={balanceData.currency} />
            </Text>
            {balanceData.availableAmount != balanceData.amount && (
              <Text className={classes.balance} elementSize="xs" elementVariant="buttons" colorVariant="froggy.12" display="flex">
                <CreditCardDown13 width={16} height={16} style={{ marginRight: 4 }} />
                <Money value={balanceData.availableAmount} currency={balanceData.availableCurrency} />
                &nbsp;{i18n.t(headerMainTranslationKeys.balanceAvailable)}
              </Text>
            )}
          </Stack>
        )}
        <NavLink
          disabled={!user?.is_onboarding_passed}
          active={false}
          display="inline-flex"
          w="auto"
          mx={-12}
          component={RouterLink}
          to={routes.ROUTES_PROFILE.profileSettingsTab.replace(':tabValue', profileTabSettings.personal)}
          label={
            <Group gap={16}>
              {(firstName || lastName) && (
                <Stack gap={0}>
                  {firstName && (
                    <Text elementSize="s" elementVariant="important" colorVariant="greyIron.18" align="right">
                      {firstName}
                    </Text>
                  )}
                  {lastName && (
                    <Text elementSize="s" elementVariant="important" colorVariant="greyIron.18" align="right">
                      {lastName}
                    </Text>
                  )}
                </Stack>
              )}
            </Group>
          }
        />
      </Group>
    </AppShell.Header>
  )
}
