import React, { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { AppShell, FixedLayout, getDefaultZIndex, Loader, LoaderProgress } from '@affstore-ds/uikit'

// import { useSurvey } from '../../../shared/hooks/useSurvey'
import { featuresStatuses } from '../../../config/brands'
import { HEADER_HEIGHT, PAGE_VERITCAL_PADDING } from '../../../shared/constants/spaces'
import { MainLayoutContextProvider, MainScrollContainerProvider, TransferGuideContainerProvider } from '../../../shared/providers'
import { routes } from '../../../shared/routes'
import { OnboardingToOfferConversionFunnel } from '../../../shared/utils'
import { getOfferNavigationLink } from '../../../shared/utils/getOfferNavigationLink'
import { HeaderMain, NavMenu } from '../../../widgets'

import { useLayoutMainStyles } from './Main.styled'

export const Main: FC = () => {
  const [viewportElement, setViewportElement] = useState<HTMLInputElement | null>(null)
  const viewportReference = useRef(null) as React.MutableRefObject<HTMLInputElement | null>

  const {
    auth: { user },
  } = useAuth()

  const location = useLocation()

  const { classes } = useLayoutMainStyles()

  // TODO: remove formbricks and this hook if survey is not needed during 2024
  // const { initSurvey } = useSurvey()

  // initSurvey()

  const isOnboarding = useMemo(() => {
    const [locationEmpty, locationOnboarding] = location.pathname.split('/')
    const [routesEmpty, routesOnboarding] = routes.ROUTES_ONBOARDING.onboarding.split('/')

    return locationEmpty === routesEmpty && locationOnboarding === routesOnboarding
  }, [location.pathname])

  useEffect(() => {
    setViewportElement(viewportReference.current)
  }, [])

  if (!isOnboarding && user && !user?.is_onboarding_passed) {
    return <Navigate to={routes.ROUTES_ONBOARDING.onboarding} />
  }

  if (isOnboarding && user && user?.is_onboarding_passed) {
    const { getMarketingURLParametersFromCookies, COOKIES_PARAMS_OBJECT } = OnboardingToOfferConversionFunnel
    const onBoardingMarketingCookies = getMarketingURLParametersFromCookies()
    const advertiserAlias = onBoardingMarketingCookies.get(COOKIES_PARAMS_OBJECT.m_oto_advertiser)
    const model = onBoardingMarketingCookies.get(COOKIES_PARAMS_OBJECT.m_oto_model)

    if (advertiserAlias && model) {
      return <Navigate to={getOfferNavigationLink({ advertiserAlias, model, active: true })} />
    }

    return <Navigate to={featuresStatuses.isNewOffersPageEnabled ? routes.ROUTES_OFFERS.offersAdvertisers : routes.ROUTES_ROOT.root} />
  }

  return (
    <FixedLayout>
      <AppShell
        layout="alt"
        classNames={{
          root: classes.root,
          header: classes.header,
          navbar: classes.navbar,
          main: classes.main,
        }}
        header={{ height: HEADER_HEIGHT }}
        navbar={{
          width: 256,
          breakpoint: -1,
          collapsed: { desktop: false, mobile: false },
        }}
        zIndex={getDefaultZIndex('app')}
      >
        <NavMenu />
        <AppShell.Header zIndex={getDefaultZIndex('app')}>
          <HeaderMain />
        </AppShell.Header>
        <AppShell.Main>
          <MainScrollContainerProvider>
            <MainLayoutContextProvider value={{ viewportElement }}>
              <div className={classes.container}>
                <Suspense
                  fallback={
                    <>
                      <LoaderProgress loading={true} timeoutInterval={500} isFixed />
                      <Loader mt={PAGE_VERITCAL_PADDING} />
                    </>
                  }
                >
                  <Outlet />
                </Suspense>
              </div>
              <TransferGuideContainerProvider />
            </MainLayoutContextProvider>
          </MainScrollContainerProvider>
        </AppShell.Main>
      </AppShell>
    </FixedLayout>
  )
}
