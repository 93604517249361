import React from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { getV3Balance, useQuery } from '@affiliate-cabinet/rest'
import { BillingTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'
import { Close13 } from '@affstore-ds/icons'
import { notificationsManagers } from '@affstore-ds/uikit'

import { commonTranslationKeys } from '../../../../shared/translations/common.translationKeys'

const notificationIdError = 'useBalanceError'

const BALANCE_STALE_TIME = 60 * 60 * 1000

export const useBalance = () => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()
  const { showNotification, hideNotification } = notificationsManagers()

  const {
    data: balanceData,
    isFetching: balanceIsFetching,
    refetch: balanceRefetch,
    isRefetching: balanceIsRefetching,
  } = useQuery<unknown, ResponseErrorInterface, BillingTypesComponents['schemas']['AffiliateBalanceV3']>(['useBalance'], () => getV3Balance(), {
    enabled: user?.is_onboarding_passed,
    retry: false,
    onError: (error) => {
      console.error(error)

      showNotification({
        id: notificationIdError,
        elementVariant: 'Error',
        title: i18n.t(commonTranslationKeys.commonSomethingWentWrong),
        message: '',
        buttonText: i18n.t(commonTranslationKeys.commonClose),
        closeButtonProps: {
          rightSection: <Close13 width={20} height={20} />,
          onClick: () => hideNotification(notificationIdError),
        },
      })
    },
    staleTime: BALANCE_STALE_TIME,
  })

  return {
    balanceData: {
      amount: balanceData?.totalAmount?.amount || 0,
      currency: balanceData?.totalAmount?.currency || '$',
      availableAmount: balanceData?.availableAmount?.amount || 0,
      availableCurrency: balanceData?.availableAmount?.currency || '$',
    },
    balanceIsFetching,
    balanceRefetch,
    balanceIsRefetching,
  }
}
