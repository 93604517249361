import React, { FC, useMemo } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { MultiSelect, MultiSelectPropertiesInterface } from '@affstore-ds/uikit'

import { localesEntity } from '../../entities/constants/localesEntity'
import { localesEntityTranslationKeys } from '../../entities/constants/localesEntity/translations/localesEntity.translationKeys'
import { commonTranslationKeys } from '../../shared/translations/common.translationKeys'
import { selectTranslationKeys } from '../../shared/translations/select.translationKeys'

export const LocalesMultiSelect: FC<Omit<MultiSelectPropertiesInterface, 'data'>> = ({ label, ...rest }) => {
  const { i18n } = useI18n()

  const LOCALE_LIST_PREPARED = useMemo(
    () =>
      localesEntity.LOCALE_LIST.map((item) => ({
        value: item.value,
        label: i18n.t(item.translationKey),
        'data-testid': `locale-item-${item.value}`,
      })).sort((a, b) => a.label.localeCompare(b.label)),
    // TODO: TASK-https://mbt.tpondemand.com/entity/394716-be-sort-locales-correctly
    [i18n],
  )

  return (
    <MultiSelect
      data={LOCALE_LIST_PREPARED}
      label={label || i18n.t(localesEntityTranslationKeys.language)}
      data-testid="locale-multi-select"
      placeholder={i18n.t(selectTranslationKeys.selectPlaceholder)}
      selectAllLabel={i18n.t(selectTranslationKeys.selectSelectAll)}
      clearLabel={i18n.t(selectTranslationKeys.selectClear)}
      maxDropdownHeight={400}
      emptySearchLabel={i18n.t(commonTranslationKeys.commonNoData)}
      position="bottom"
      {...rest}
    />
  )
}
