import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { getIdentityImagePath } from '@affstore-ds/images'
import { Button, Group, Image, Modal, Stack, Text, Title } from '@affstore-ds/uikit'

import { LegalNoticeModalPropertiesInterface } from './TransferInfoModal.types'

import { commonTranslationKeys } from '../../../../../../../translations/common.translationKeys'
import { transferPartnerTranslationKeys } from '../../translations/TransferPartner.translationKeys'

export const TransferInfoModal: FC<LegalNoticeModalPropertiesInterface> = ({ opened, onAccept, isLoading }) => {
  const { i18n } = useI18n()

  return (
    <Modal
      centered
      hideHeader
      hideOpenButton
      size="420px"
      elementVariant="default"
      opened={opened}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
    >
      <Stack gap={8} align="center">
        <Image src={getIdentityImagePath('LogoDarkBrandEmblem')} width={40} height={40} />
        <Title elementSize="l" order={3} mt={8}>
          {i18n.t(transferPartnerTranslationKeys.infoTransferTitle)}
        </Title>
        <Text elementVariant="default" elementSize="s" align="center">
          {i18n.t(transferPartnerTranslationKeys.infoTransferDescription)}
        </Text>
      </Stack>
      <Group mt={16} justify="space-between">
        <Button onClick={onAccept} isLoading={isLoading} fullWidth>
          {i18n.t(commonTranslationKeys.commonOkayGotIt)}
        </Button>
      </Group>
    </Modal>
  )
}
