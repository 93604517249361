import { createStyles, getDefaultZIndex, rem } from '@affstore-ds/uikit'

import { MAIN_LAYOUT_WIDTH } from '../../../shared/constants/spaces'

export const useLayoutMainStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    boxSizing: 'border-box',

    // Right border instead of border-right for not shifting the content
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: rem(1),
      backgroundColor: theme.other.colors.greyIron[4],
      zIndex: getDefaultZIndex('modal') + 1,
    },
  },
  body: {
    flexGrow: 2,
  },
  main: {
    'p, span, li': {
      '& > a': {
        fontSize: 'inherit !important',
      },
    },
  },
  scrollContainerRoot: {
    position: 'relative',
    height: theme.other.viewport.height,
    scrollBehavior: 'smooth',
  },
  scrollbar: {
    zIndex: getDefaultZIndex('app'),
    '&&[data-orientation="vertical"] .thumb': {
      backgroundColor: theme.other.colors.greyIron[13],
      opacity: '30%',
    },
    '&&[data-orientation="horizontal"] .thumb': {
      backgroundColor: theme.other.colors.greyIron[13],
      opacity: '30%',
    },
  },
  container: {
    width: MAIN_LAYOUT_WIDTH,
    padding: `0 ${rem(32)} ${rem(32)} ${rem(32)}`,
  },
  rootWrapper: {
    position: 'relative',
  },
  header: {
    position: 'absolute',
  },
  navbar: {
    position: 'absolute',
  },
}))
