import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { Center, Group, Text } from '@affstore-ds/uikit'

import { getBrandConfig } from '../../../../../config/brands'
import { AdvertiserLogo } from '../../../../../shared/assets'
import { OnboardingToOfferConversionFunnel } from '../../../../../shared/utils'
import { GuestFooter } from '../../../../../widgets'

import { useAuthWrapperStyles } from './AuthWrapper.styled'

const { brandConfig } = getBrandConfig()

export const AuthWrapper = () => {
  const { classes } = useAuthWrapperStyles()

  const advertiserLogo = useMemo(() => {
    const { COOKIES_PARAMS_OBJECT, getMarketingURLParametersFromCookies, ADVERTISER_ALIASES_TO_LOGO } = OnboardingToOfferConversionFunnel
    const advertiserAlias = getMarketingURLParametersFromCookies().get(COOKIES_PARAMS_OBJECT.m_oto_advertiser) || ''

    if (advertiserAlias) {
      const logo = ADVERTISER_ALIASES_TO_LOGO[advertiserAlias]

      if (!logo) {
        console.error(`m_oto_ > Advertiser logo not found. Advertiser: ${advertiserAlias}`)

        return null
      }

      return logo
    }

    return null
  }, [])

  return (
    <Center className={classes.wrapper}>
      <div className={classes.formWrapper}>
        <Group wrap="nowrap" align="center" justify="center" gap={8} mah="20px">
          <AdvertiserLogo size="m" iconName={brandConfig.logo} w={120} alt="Logo" />
          {advertiserLogo && (
            <Text elementSize="xl" elementVariant="header">
              ×
            </Text>
          )}
          {advertiserLogo}
        </Group>

        <Outlet />
      </div>

      <GuestFooter />
    </Center>
  )
}
