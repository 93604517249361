import React, { useMemo } from 'react'
import { DateTime } from 'luxon'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { getNextPaymentDate, useQuery } from '@affiliate-cabinet/rest'
import { BillingTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'
import { Close13 } from '@affstore-ds/icons'
import { notificationsManagers, Text } from '@affstore-ds/uikit'

import { useRegularPaymentsSettings } from '../useRegularPaymentsSettings'
import { useGetNextPaymentDateTranslationKeys } from './translations/useGetNextPaymentDate.translationKeys'

import { DATE_MODEL_FORMAT } from '../../../shared/constants'
import { commonTranslationKeys } from '../../../shared/translations/common.translationKeys'

const notificationIdError = 'useGetNextPaymentDateError'

export const useGetNextPaymentDate = () => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()
  const { showNotification, hideNotification } = notificationsManagers()

  const { conditionsData } = useRegularPaymentsSettings()

  const {
    data: nextPaymentDate,
    isFetching: nextPaymentDateIsFetching,
    isRefetching: nextPaymentDateIsReFetching,
  } = useQuery<unknown, ResponseErrorInterface, BillingTypesComponents['schemas']['PaymentDate']>(
    ['useGetNextPaymentDate'],
    () => getNextPaymentDate(),
    {
      enabled: user?.is_onboarding_passed,
      retry: false,
      onError: (error) => {
        console.error(error)

        showNotification({
          id: notificationIdError,
          elementVariant: 'Error',
          title: i18n.t(commonTranslationKeys.commonSomethingWentWrong),
          message: '',
          buttonText: i18n.t(commonTranslationKeys.commonClose),
          closeButtonProps: {
            rightSection: <Close13 width={20} height={20} />,
            onClick: () => hideNotification(notificationIdError),
          },
        })
      },
    },
  )

  const dateFrom = useMemo(
    () => (nextPaymentDate?.dateFrom ? DateTime.fromFormat(nextPaymentDate.dateFrom, DATE_MODEL_FORMAT) : undefined),
    [nextPaymentDate?.dateFrom],
  )
  const dateTo = useMemo(
    () => (nextPaymentDate?.dateTo ? DateTime.fromFormat(nextPaymentDate.dateTo, DATE_MODEL_FORMAT) : undefined),
    [nextPaymentDate?.dateTo],
  )

  const regularPaymentInfoHint = useMemo(() => {
    const rangeMonthFormat = `MMMM`
    const range = `${dateFrom?.toFormat('dd')}-${dateTo?.toFormat('dd')} ${dateTo?.toFormat(rangeMonthFormat, {
      locale: i18n.locale(),
    })}`

    return (
      <span>
        {range}{' '}
        <Text component="span" elementSize="s" elementVariant="default" colorVariant="greyIron.16">
          {i18n.t(useGetNextPaymentDateTranslationKeys.paymentRegularNextPayment)}
        </Text>
      </span>
    )
  }, [dateFrom, dateTo, i18n])

  const paymentDate = useMemo(() => {
    const dateFromDiffNow = dateFrom ? dateFrom.diffNow('second') : undefined
    const dateToDiffNow = dateTo ? dateTo.diffNow('second') : undefined
    const isBetween = Number(dateFromDiffNow) <= 0 && Number(dateToDiffNow) >= 0

    if (isBetween && !conditionsData.isConditionsDisplayable) {
      return i18n.t(useGetNextPaymentDateTranslationKeys.paymentsInProgress)
    }

    if (dateFrom && dateTo) {
      return `${dateFrom?.toFormat('dd')}-${dateTo?.toFormat('dd')} ${dateTo?.toFormat('MMMM', {
        locale: i18n.locale(),
      })}`
    }

    return undefined
  }, [conditionsData.isConditionsDisplayable, dateFrom, dateTo, i18n])

  return {
    regularPaymentInfoHint,
    paymentDate,
    nextPaymentDateIsFetching,
    nextPaymentDateIsReFetching,
  }
}
