import React from 'react'

import { ComboboxItem, SelectPropertiesInterface, Stack, Text } from '@affstore-ds/uikit'

export const AdvertiserModelItem: SelectPropertiesInterface['renderOption'] = ({ option }) => {
  const { label, disabled, description } = option as ComboboxItem & { description?: string }

  return (
    <Stack gap={0}>
      <Text elementVariant="default" elementSize="s" colorVariant={disabled ? 'greyIron.9' : 'greyIron.20'}>
        {label}
      </Text>
      {description && (
        <Text elementVariant="default" elementSize="s" colorVariant="raspberry.12">
          {description}
        </Text>
      )}
    </Stack>
  )
}
