import { createStyles, getDefaultZIndex, rem } from '@affstore-ds/uikit'

import { HEADER_HEIGHT } from '../../constants/spaces'

export const useMainScrollContainerStyles = createStyles((theme) => ({
  scrollContainerRoot: {
    position: 'relative',
    height: `calc(${theme.other.viewport.height} - ${rem(HEADER_HEIGHT)})`,
    scrollBehavior: 'smooth',
  },
  scrollbar: {
    zIndex: getDefaultZIndex('app'),
  },
  thumb: {
    backgroundColor: theme.other.colors.greyIron[13],
    opacity: '30%',
  },
}))
