import React from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { getApiV3PromoBannersIdDownload } from '@affiliate-cabinet/rest'
import { FlagNamesType, getFlagsPath } from '@affstore-ds/flags'
import { Download0213 } from '@affstore-ds/icons'
import { Avatar, Card, Divider, Group, Loader, Stack } from '@affstore-ds/uikit'

import { usePromoBannersId } from './base/hooks/usePromoBannersId'

import { localesEntityTranslationKeys } from '../../../../entities/constants/localesEntity/translations/localesEntity.translationKeys'
import { MainPageHeader } from '../../../../shared/components/MainPageHeader'
import { TableEmptyData } from '../../../../shared/components/TableEmptyData'

import { BannerModalGenerateLink } from './base/containers/BannerModalGenerateLink'
import { PromoBannersIdFilters } from './base/containers/PromoBannersIdFilters'
import { usePromoBannerDividerStyles } from './base/styles/PromoBannersDivider.styled'
import { promoBannersByIdTranslationKeys } from './base/translations/promoBannersById.translationKeys'

export const PromoBannersById = () => {
  const { i18n } = useI18n()
  const { classes: promoBannerDividerClasses } = usePromoBannerDividerStyles()

  const { isFetching, rootBanner, childBanners, childBannersLocales, handleBackLink, handleFiltersChange, filters } = usePromoBannersId()

  if (isFetching) {
    return <Loader />
  }

  return (
    <Stack gap={32}>
      <MainPageHeader
        withBackButton
        onBackButtonClick={handleBackLink}
        title={rootBanner?.name}
        subtitle={i18n.t(promoBannersByIdTranslationKeys.promoBannerByIdDescription)}
      />
      <PromoBannersIdFilters filters={filters} onChange={handleFiltersChange} loading={isFetching} imageSizes={rootBanner?.sizes} />

      {childBannersLocales.length > 0 && (
        <Stack gap={32} align="stretch">
          {childBannersLocales.map((locale) => (
            <Stack gap={32} key={locale}>
              <Group gap={8}>
                <Divider
                  // w={812}
                  w="100%"
                  label={i18n.t(localesEntityTranslationKeys[locale as keyof typeof localesEntityTranslationKeys])}
                  labelPosition="left"
                  classNames={promoBannerDividerClasses}
                  colorVariant="greyIron.4"
                />
                {/*<Link*/}
                {/*  href={getApiV3PromoBannersIdDownload({*/}
                {/*    id: childBanners[locale]?.[0]?.bannerId || 0,*/}
                {/*    locale: locale,*/}
                {/*    imageSizes: filters.imageSizes,*/}
                {/*    fileFormats: filters.fileFormats,*/}
                {/*  })}*/}
                {/*  target="_blank"*/}
                {/*  rel="noreferrer noopener"*/}
                {/*  elementVariant={ButtonVariantEnum.TertiaryMain}*/}
                {/*  elementSize={ButtonSizeEnum.S}*/}
                {/*  rightSection={<Download0213 />}*/}
                {/*>*/}
                {/*  Download · {childBanners[locale].length}*/}
                {/*</Link>*/}
              </Group>
              <Group key={locale} style={{ rowGap: 32, columnGap: 24 }} align="stretch">
                {childBanners[locale].map((banner) => (
                  <Card
                    key={banner?.linkId}
                    elementSize="S"
                    externalLink={getApiV3PromoBannersIdDownload({
                      id: banner?.bannerId || 0,
                      locale: banner?.locale,
                      imageSizes: banner?.size ? [banner?.size] : undefined,
                    })}
                    externalLinkIcon={<Download0213 />}
                    generateLinkButtonProps={{
                      display: 'none',
                    }}
                    customButtons={
                      <BannerModalGenerateLink
                        locale={banner?.locale || 'en_US'}
                        bannerId={banner?.bannerId || 0}
                        advertiserId={rootBanner?.advertiserId || 1}
                        bannerLink={banner?.link || ''}
                        size={banner?.size || ''}
                      />
                    }
                    generateLinkText=""
                    imageAlt="promo banner image"
                    imageUrl={banner?.link || ''}
                    badges={[
                      {
                        text: banner?.size,
                      },
                      {
                        icon: <Avatar avatarSize={16} src={getFlagsPath(banner?.locale.split('_')[1].toLowerCase() as FlagNamesType)} />,
                      },
                    ]}
                  />
                ))}
              </Group>
            </Stack>
          ))}
        </Stack>
      )}
      {childBannersLocales.length === 0 && <TableEmptyData />}
    </Stack>
  )
}
