import React, { FC, useCallback, useEffect, useMemo } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { I18nDictionaryLanguageKeysType, i18nLanguagesKeys, i18nLanguagesMap, i18nLanguagesNameMap, useI18n } from '@affiliate-cabinet/i18n'
import { onChangeLocaleHandler, useMutation } from '@affiliate-cabinet/rest'
import { CoreTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'
import { Globe13 } from '@affstore-ds/icons'
import { ButtonVariantEnum, Dropdown, DropDownSizeEnum, getDefaultZIndex } from '@affstore-ds/uikit'

import { LanguageDropdownPropertiesInterface } from './LanguageDropdown.types'

import { getBrandConfig } from '../../config/brands'

const { brandConfig } = getBrandConfig()

export const LanguageDropdown: FC<LanguageDropdownPropertiesInterface> = ({ type = 'cabinet' }) => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()

  const languageKey = useMemo(() => i18n.locale() as I18nDictionaryLanguageKeysType, [i18n])
  const userLocaleKey = useMemo(() => user?.ui_language?.split('_')[0] as I18nDictionaryLanguageKeysType, [user?.ui_language])
  const allowedLanguages = useMemo(() => i18nLanguagesKeys.filter((language) => brandConfig.languages.includes(language)), [])

  const onChangeLocaleHandlerMutation = useMutation<
    unknown,
    ResponseErrorInterface,
    Pick<CoreTypesComponents['schemas']['UpdateProfileQueryDto'], 'locale'>
  >(onChangeLocaleHandler)

  const handleClick = useCallback(
    (langKey: I18nDictionaryLanguageKeysType) => () => {
      i18n.handleLocale(langKey)

      if (user && type === 'cabinet') {
        onChangeLocaleHandlerMutation.mutate(
          {
            locale: i18nLanguagesMap[langKey],
          },
          {
            onError: (error) => {
              console.error(error)
            },
          },
        )
      }
    },
    [i18n, onChangeLocaleHandlerMutation, type, user],
  )

  useEffect(() => {
    if (userLocaleKey && userLocaleKey !== languageKey) {
      i18n.handleLocale(userLocaleKey)
    }
    /* This useEffect shouldn't be called after languageKey changing */
  }, [userLocaleKey]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      targetElementLeftIcon={<Globe13 />}
      targetElementVariant={ButtonVariantEnum.SecondaryMainLine}
      targetElementContent={i18nLanguagesNameMap[languageKey] as string}
      elementSize={type === 'guest' ? DropDownSizeEnum.S : DropDownSizeEnum.M}
      data-testid="dropdown-language"
      zIndex={getDefaultZIndex('popover')}
    >
      <Dropdown.Items>
        {allowedLanguages.map((langKey) => (
          <Dropdown.Item
            key={langKey}
            onClick={handleClick(langKey)}
            data-testid={`dropdown-language-item-${langKey}`}
            data-current={langKey === languageKey}
          >
            {i18nLanguagesNameMap[langKey]}
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}
