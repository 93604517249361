import React from 'react'

import { Skeleton, Stack, useMantineTheme } from '@affstore-ds/uikit'

export const GuestSkeleton = () => {
  const theme = useMantineTheme()

  return (
    <Stack
      gap={0}
      w={576}
      px={48}
      py={100}
      style={{
        boxSizing: 'border-box',
        backgroundColor: theme.other.colors.white[0],
        borderRadius: theme.other.borderRadius[8],
        boxShadow: theme.other.shadows.s,
      }}
    >
      <Skeleton width={120} height={18} borderRadius={8} centered />

      <Skeleton width={232} height={36} borderRadius={100} mt={24} centered />

      <Skeleton width={43} height={20} borderRadius={100} mt={24} />
      <Skeleton width="100%" height={40} borderRadius={100} mt={6} />

      <Skeleton width={43} height={20} borderRadius={100} mt={24} />
      <Skeleton width="100%" height={40} borderRadius={100} mt={6} />

      <Skeleton width={154} height={20} borderRadius={100} mt={16} />

      <Skeleton width="100%" height={40} borderRadius={100} mt={24} />

      <Skeleton width={247} height={40} borderRadius={100} mt={24} />
    </Stack>
  )
}
