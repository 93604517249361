import React, { FC, MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Copy0313, Link0113 } from '@affstore-ds/icons'
import { Button, ButtonSizeEnum, ButtonVariantEnum, CopyButton, Link, Modal, Stack, TextInput, Tooltip } from '@affstore-ds/uikit'

import { usePromoBannerLinkGenerate } from '../../hooks/usePromoBannerLinkGenerate'

import { useAdvertiser } from '../../../../../../../entities/advertiser'
import { AffTrackInput } from '../../../../../../../features/AffTrackInput'
import { OffersModelsByAdvertiserSelect } from '../../../../../../../features/OffersModelsByAdvertiserSelect'
import { PromoBannerLandingsPageLinksSelect } from '../../../../../../../features/PromoBannerLandingsPageLinksSelect'
import { handleNavigate } from '../../../../../../../shared/navigation/handleNavigate'
import { buttonCopyTranslationKeys } from '../../../../../../../shared/translations/buttonCopy.translationKeys'
import { promoLandingsTranslationKeys } from '../../../../../landings/translations/PromoLandings.translationKeys'

import { bannerModalGenerateLinkTranslationKeys } from './translations/bannerModalGenerateLink.translationKeys'

export const BannerModalGenerateLink: FC<{ advertiserId: number; size: string; bannerLink: string; bannerId: number; locale: string }> = ({
  advertiserId,
  size,
  bannerLink,
  bannerId,
  locale,
}) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  const { advertiser } = useAdvertiser(advertiserId)
  const { form, link, modelError, tabOfferLink, tabOfferLinkText } = usePromoBannerLinkGenerate({
    size,
    bannerLink,
    advertiserId,
    advertiserAlias: advertiser?.alias || '',
  })

  const onClick: MouseEventHandler = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const isDisabledCopy = !form.values.landingLink || !form.values.model || Boolean(modelError)

  return (
    <Modal
      centered
      elementVariant="success"
      data-testid="banner-generate-link-modal"
      Icon={Link0113}
      buttonOpenProps={{
        onClick,
        style: { flex: 1 },
        children: i18n.t(promoLandingsTranslationKeys.promoLandingsGenerateLink),
        elementVariant: ButtonVariantEnum.SecondaryMain,
        elementSize: ButtonSizeEnum.M,
        'data-testid': 'card-button-generate-link',
      }}
      title={i18n.t(promoLandingsTranslationKeys.promoLandingsGenerateLink)}
      titleSize="l"
      size={420}
    >
      <form name="banner-generate-link-form">
        <Stack gap={16}>
          <PromoBannerLandingsPageLinksSelect
            name="landingLink"
            advertiserId={advertiserId}
            bannerId={bannerId}
            locale={locale}
            {...form.getInputProps('landingLink')}
          />
          <OffersModelsByAdvertiserSelect
            name="model"
            advertiserId={advertiserId}
            {...form.getInputProps('model')}
            error={form.getInputProps('model').error || modelError}
          />
          <AffTrackInput
            name="afftrack"
            placeholder={i18n.t(bannerModalGenerateLinkTranslationKeys.afftrackTypeHere)}
            {...form.getInputProps('afftrack')}
          />
          <TextInput
            readOnly
            disabled={isDisabledCopy}
            aria-readonly="true"
            name="link-value"
            value={link}
            data-testid="banner-link-value-to-copy"
            label={i18n.t(promoLandingsTranslationKeys.promoLandingsGenerateLink)}
          />
          {!modelError && (
            <CopyButton value={link}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? i18n.t(buttonCopyTranslationKeys.tooltipCopied) : ''} withArrow={false}>
                  <Button disabled={isDisabledCopy} type="button" rightSection={<Copy0313 />} onClick={copy} mt={8}>
                    {i18n.t(bannerModalGenerateLinkTranslationKeys.promoModalButtonCopyLink)}
                  </Button>
                </Tooltip>
              )}
            </CopyButton>
          )}
          {modelError && tabOfferLink && tabOfferLinkText && (
            <Link elementVariant={ButtonVariantEnum.PrimaryMain} href={tabOfferLink} onClick={handleNavigate(navigate, tabOfferLink)} mt={8}>
              {tabOfferLinkText}
            </Link>
          )}
        </Stack>
      </form>
    </Modal>
  )
}
