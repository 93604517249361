import * as Sentry from '@sentry/react'

import { BrandPropertiesInterface, FeatureFlagInterface } from '../../../../../appConfig'
import { AFFSTORE_SITE_ID_LIST } from '../../shared/constants'

import { featureFlagsDictionary } from './featureFlags.dictionary'

let normalizedFeatureFlags: Record<string, Record<string, FeatureFlagInterface>> | undefined = undefined

export const getBrandConfig = (): {
  brandConfig: BrandPropertiesInterface
} => ({ brandConfig: window.__APP_CONFIG__ })

export const isMainBrand = () => {
  const { brandConfig } = getBrandConfig()

  return AFFSTORE_SITE_ID_LIST.includes(brandConfig.siteId)
}

export const getRefferalLink = (userId?: number) => {
  const { brandConfig } = getBrandConfig()
  const referralId = userId || ''

  return `${brandConfig.subAffiliateLink}${referralId}`
}

class FeaturesStatuses {
  private findFeatureFlag({ name, category }: { category: string; name: string }) {
    const { brandConfig } = getBrandConfig()

    if (!normalizedFeatureFlags) {
      normalizedFeatureFlags = brandConfig.featureFlags?.reduce<{ [category: string]: { [name: string]: FeatureFlagInterface } }>(
        (accumulator, item) => {
          if (!accumulator[item.category]) {
            accumulator[item.category] = {}
          }
          accumulator[item.category][item.name] = item

          return accumulator
        },
        {},
      )
    }

    const featureFlag = normalizedFeatureFlags?.[category][name]

    if (!featureFlag) {
      Sentry.captureException(new Error(`Feature flag with category=${category} and name=${name} is not found`), {
        tags: {
          featureFlags: true,
          featureFlagCategory: category,
          featureFlagName: name,
        },
      })
    }

    if (featureFlag) {
      featureFlag.enabled = featureFlag.calculateEnabled ? featureFlag.calculateEnabled() : featureFlag.enabled

      const featureFlagFromLS = localStorage.getItem(`${category}-${name}`)

      if (featureFlagFromLS) {
        featureFlag.enabled = featureFlagFromLS === 'true'
      }
    }

    return featureFlag
  }

  public readonly isInfoPageEnabled = this.findFeatureFlag(featureFlagsDictionary.infoPage)?.enabled
  public readonly isBlogLinkEnabled = this.findFeatureFlag(featureFlagsDictionary.blogLink)?.enabled
  public readonly isInfoLinkEnabled = this.findFeatureFlag(featureFlagsDictionary.infoLink)?.enabled

  public readonly isKYCEnabled = this.findFeatureFlag(featureFlagsDictionary.kyc)?.enabled

  public readonly isPromoPageEnabled = this.findFeatureFlag(featureFlagsDictionary.promoPage)?.enabled

  public readonly isNewOffersPageEnabled = this.findFeatureFlag(featureFlagsDictionary.newOffersPages)?.enabled
  public readonly isOfferFavouriteEnabled = this.findFeatureFlag(featureFlagsDictionary.offerFavourite)?.enabled
  public readonly withOffersFilters = this.findFeatureFlag(featureFlagsDictionary.offersFilters)?.enabled
  public readonly isOffersRestrictionGradeEnabled = this.findFeatureFlag(featureFlagsDictionary.offersRestrictionGrade)?.enabled

  public readonly isProgramIBEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.onboardingProgramIB)?.enabled)
  public readonly isProgramAFFILIATEEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.onboardingProgramIB)?.enabled)
  public readonly isOnboardingPhoneNumberInputEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.onboardingPhoneNumberInput)?.enabled)

  public readonly isSignInEnabled = this.findFeatureFlag(featureFlagsDictionary.signin)?.enabled
  public readonly isRegistrationEnabled = this.findFeatureFlag(featureFlagsDictionary.registration)?.enabled

  public readonly isSubAffiliatePageEnabled = this.findFeatureFlag(featureFlagsDictionary.subAffiliatePage)?.enabled

  public readonly isContactInfoTabEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.profileContactInfoTab)?.enabled)
  public readonly isPersonalTabEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.profilePersonalTab)?.enabled)
  public readonly isTypeOfAffiliationEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.profilePersonalTypeOfAffiliation)?.enabled)
  public readonly isSubscriptionEnabled = Boolean(this.findFeatureFlag(featureFlagsDictionary.profilePersonalSubscription)?.enabled)

  public readonly isTop10Enabled = this.findFeatureFlag(featureFlagsDictionary.top10)?.enabled
  public readonly isTop10PageEnabled = this.findFeatureFlag(featureFlagsDictionary.top10Page)?.enabled

  public readonly isNewBacklinkTabOfferPageEnabled = this.findFeatureFlag(featureFlagsDictionary.newBacklinkTabOfferPage)?.enabled

  public readonly isTransferedClientSurveyEnabled = this.findFeatureFlag(featureFlagsDictionary.transferedClientSurvey)?.enabled
  public readonly isUserStatisticsGroupByDatesEnabled = this.findFeatureFlag(featureFlagsDictionary.userStatisticsGroupByDates)?.enabled
  public readonly isUserStatisticsInitialRequestEnabled = this.findFeatureFlag(featureFlagsDictionary.userStatisticsInitialRequest)?.enabled

  public readonly isTelegramPostbacksEnabled = this.findFeatureFlag(featureFlagsDictionary.telegramPostBacks)?.enabled

  public readonly isContestsPageEnabled = this.findFeatureFlag(featureFlagsDictionary.contestsPage)?.enabled
  public readonly isContestsPageMyContestsFilterEnabled = this.findFeatureFlag(featureFlagsDictionary.contestsPageMyContestsFilter)?.enabled

  public readonly isPaymentMethodPixEnabled = this.findFeatureFlag(featureFlagsDictionary.paymentMethodPix)?.enabled
  public readonly isUserStatiscticProfitColumnEnabled = this.findFeatureFlag(featureFlagsDictionary.userStatisticsProfitColumn)?.enabled
}

export const featuresStatuses = new FeaturesStatuses()
