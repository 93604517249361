import React from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { postV3AffstoreTransferLogin, useMutation } from '@affiliate-cabinet/rest'
import { BillingTypesPaths, ResponseErrorInterface } from '@affiliate-cabinet/types'
import { Close13 } from '@affstore-ds/icons'
import { notificationsManagers } from '@affstore-ds/uikit'

import { commonTranslationKeys } from '../../../../../../translations/common.translationKeys'

const notificationIdError = 'usePostV3AffstoreTransferLoginError'

export const usePostV3AffstoreTransferLogin = () => {
  const { i18n } = useI18n()
  const { showNotification, hideNotification } = notificationsManagers()
  const postV3AffstoreTransferLoginMutation = useMutation<
    unknown,
    ResponseErrorInterface,
    BillingTypesPaths['/v3/affstore-transfer-login']['post']['requestBody']['content']['application/json']
  >(postV3AffstoreTransferLogin, {
    onError: () => {
      showNotification({
        id: notificationIdError,
        elementVariant: 'Error',
        title: i18n.t(commonTranslationKeys.commonSomethingWentWrong),
        message: '',
        buttonText: i18n.t(commonTranslationKeys.commonClose),
        closeButtonProps: {
          rightSection: <Close13 width={20} height={20} />,
          onClick: () => hideNotification(notificationIdError),
        },
      })
    },
  })

  return { postV3AffstoreTransferLoginMutation }
}
