import React from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Loader, Stack, Text } from '@affstore-ds/uikit'

import { transferLoaderTranslationKeys } from './translations/TransferLoader.translationKeys'
import { useTransferLoaderStyles } from './TransferLoader.styled'

export const TransferLoader = () => {
  const { i18n } = useI18n()
  const { classes } = useTransferLoaderStyles()

  return (
    <Stack className={classes.container} gap={24}>
      <Loader classNames={{ root: classes.loader }} />
      <Text elementVariant="header" elementSize="l">
        {i18n.t(transferLoaderTranslationKeys.explanation)}
      </Text>
    </Stack>
  )
}
